.Product {
  .prijs {
    font-size: 12px;
    strong {
      font-weight: 500;
      font-size: 28px;
      padding-right: 5px;
    }
  }
  .content--product {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    display: block;
    -webkit-box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
    -moz-box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
    box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
    .content--product-content {
      color: $white;
      font-family: Roboto;
      font-weight: 900;
      font-size: 22px;
      text-transform: uppercase;
      position: absolute;
      z-index: 9999;
      bottom: 15px;
      width: 100%;
      line-height: 28px;
      .title {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 15px;
      }
      .btn {
        font-size: 16px;
        text-transform: initial;
        margin-right: 15px;
      }
      .prijs {
        margin-bottom: 0;
        text-align: left;
        margin-left: 15px;
        strong {
          font-weight: 400;
          font-size: 24px;
        }
      }
      &::first-letter {
        font-size: 28px;
      }
    }
    .content--product-cirkel {
      background: #008aaf;
      mix-blend-mode: hard-light;
      position: absolute;
      z-index: 9999;
      border-radius: 50%;
      width: 200%;
      height: 100%;
      padding-left: 50px;
      padding-right: 50px;
      left: -50%;
      bottom: -65%;
    }
    .content--product-content {
      .btn {
        font-size: 16px;
        text-transform: initial;
      }
    }
  }
  .product--gallerij-top {
    min-height: 450px;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    .swiper-slide {
      background-size: cover !important;
      background-position: center;
      border-radius: 5px;
      overflow: hidden;
    }
  }
  .product--gallerij-thumbs {
    width: 100%;
    height: 110px;
    .swiper-slide {
      background-size: cover !important;
      background-position: center;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      &.swiper-slide-thumb-active {
        border: solid 4px $secondary-theme;
        box-sizing: border-box;
      }
    }
  }
  .product--informatie {
    .btn {
      color: $white;
    }
    p,
    ul {
      margin-bottom: 5px;
    }
    li {
      position: relative;
      padding-left: 25px;
    }
    i {
      color: $primary-theme;
      position: absolute;
      left: 0;
      line-height: 24px;
    }
    a {
      color: $color-txt;
      &:hover {
        text-transform: none;
      }
    }
  }
  .product--social {
    font-size: 25px;
    a {
      margin-right: 10px;
    }
    .fa-facebook-f {
      color: #3b5998;
    }
    .fa-linkedin-in {
      color: #0e76a8;
    }
    .fa-twitter {
      color: #1da1f2;
    }
  }
  .product--content {
    background: $white;
    border-radius: 5px;
    p {
      display: block;
      width: 100%;
      padding-right: 30px;
    }
  }
}
