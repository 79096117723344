.content {
  background: url('../images/wood-pattern.jpg');
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.8) 100%);
  }
  .content-afbeelding {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
    -moz-box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
    box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
  }
}
.content--categorie {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  display: block;
  -webkit-box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
  -moz-box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
  box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
  .content--categorie-titel {
    color: $white;
    font-family: Roboto;
    font-weight: 900;
    @media (max-width: 992px) {
      font-size: 15px;
    }
    @media (min-width: 992px) {
      font-size: 22px;
    }
    text-transform: uppercase;
    position: absolute;
    z-index: 9999;
    bottom: 15px;
    left: 15px;
    line-height: 28px;
    .title {
      margin-bottom: 0;
    }
    &::first-letter {
      @media (max-width: 992px) {
        font-size: 20px;
      }
      @media (min-width: 992px) {
        font-size: 28px;
      }
    }
  }
  .content--categorie-cirkel {
    background: #008aaf;
    mix-blend-mode: hard-light;
    position: absolute;
    z-index: 9999;
    border-radius: 50%;
    width: 200%;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
    left: -50%;
    bottom: -67.5%;
  }
}
.nieuws--artikel-afbeelding {
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 10px 10px 35px 0 rgba(114, 102, 89, 0.2);
  -moz-box-shadow: 10px 10px 35px 0 rgba(114, 102, 89, 0.2);
  box-shadow: 10px 10px 35px 0 rgba(114, 102, 89, 0.2);
}
.nieuws--artikel-content {
  background: $white;
  margin-left: -30px;
  padding-left: 30px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 0 5px 5px 0;
  -webkit-box-shadow: 10px 10px 35px 0 rgba(114, 102, 89, 0.2);
  -moz-box-shadow: 10px 10px 35px 0 rgba(114, 102, 89, 0.2);
  box-shadow: 10px 10px 35px 0 rgba(114, 102, 89, 0.2);
  text-align: center;
  p {
    margin-bottom: 10px;
    text-align: left;
    strong {
      font-size: 20px;
    }
    i {
      color: #7d7d7d;
    }
  }
}
.pagination {
  display: inline-block;
  text-align: center;
  width: 100%;
  .btn {
    padding: 7.5px;
    padding-left: 12.5px;
    padding-right: 12.5px;
    font-size: 24px;
    line-height: 30px;
  }
  .spacer {
    line-height: 35px;
  }
  .num {
    border: solid 1px $primary-theme;
    border-radius: 5px;
    margin: 0 5px;
    display: inline-block;
    &:hover {
      background: $primary-theme;
      a {
        color: $white;
      }
    }
    &.current {
      padding: 10px 17.5px;
      background: $primary-theme;
      color: $white;
    }
    a {
      color: $color-txt;
      text-decoration: none;
      font-weight: 500;
      padding: 10px 17.5px;
      line-height: 45px;
    }
  }
}
.col-xl-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 1200px) {
  .col-xl-15 {
    max-width: 20%;
    float: left;
  }
}
