// ============================================
// Colors
// ============================================

@function shade($color, $percent) {
  @return mix(black, $color, $percent);
}

@function light($color, $percent) {
  @return mix(white, $color, $percent);
}
