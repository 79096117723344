#Form_ContactFormulier {
  input:not([type='radio']):not([type='checkbox']),
  optgroup,
  select,
  textarea {
    background: $white;
    width: 100%;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 15px;
    -webkit-box-shadow: 0 0 10px 0 rgba(114, 102, 89, 0.2);
    -moz-box-shadow: 0 0 10px 0 rgba(114, 102, 89, 0.2);
    box-shadow: 0 0 10px 0 rgba(114, 102, 89, 0.2);
  }
}
#Form_OfferteFormulier {
  input:not([type='radio']):not([type='checkbox']),
  optgroup,
  select,
  textarea {
    background: $white;
    width: 100%;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 15px;
    -webkit-box-shadow: 0 0 10px 0 rgba(114, 102, 89, 0.2);
    -moz-box-shadow: 0 0 10px 0 rgba(114, 102, 89, 0.2);
    box-shadow: 0 0 10px 0 rgba(114, 102, 89, 0.2);
  }
  input:disabled {
    background: #f3f3f3 !important;
    color: #c1c1c1;
  }
}
