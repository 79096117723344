.CategoriePage {
  .content--product {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    display: block;
    -webkit-box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
    -moz-box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
    box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
    .content--product-content {
      color: $white;
      font-family: Roboto;
      font-weight: 900;
      font-size: 22px;
      text-transform: uppercase;
      position: absolute;
      z-index: 9999;
      bottom: 15px;
      width: 100%;
      line-height: 28px;
      .title {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 15px;
      }
      .btn {
        font-size: 16px;
        text-transform: initial;
        margin-right: 15px;
      }
      .prijs {
        margin-bottom: 0;
        text-align: left;
        margin-left: 15px;
        strong {
          font-weight: 400;
          font-size: 24px;
        }
      }
      &::first-letter {
        font-size: 28px;
      }
    }
    .content--product-cirkel {
      background: #008aaf;
      mix-blend-mode: hard-light;
      position: absolute;
      z-index: 9999;
      border-radius: 50%;
      width: 200%;
      height: 100%;
      padding-left: 50px;
      padding-right: 50px;
      left: -50%;
      bottom: -65%;
    }
  }
}
