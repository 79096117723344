.banner {
  .swiper-slide {
    height: 400px;
    background-size: cover !important;
    background-position: center !important;
    .swiper-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      text-align: center;
      p {
        text-shadow: 0 -4px 14px rgba(0, 0, 0, 1);
        font-family: Handlee Regular;
        @media (max-width: 992px) {
          font-size: 50px;
        }
        @media (min-width: 992px) {
          font-size: 70px;
        }
        color: $white;
      }
      .btn {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}
.pagebanner {
  background-position: center;
  background-size: cover;
  height: 250px;
  .banner-content {
    h1 {
      text-shadow: 0 -4px 14px rgba(0, 0, 0, 1);
      font-family: Handlee Regular;
      font-size: 70px;
      color: $white;
      text-transform: initial;
      &::first-letter {
        font-size: 70px;
      }
    }
  }
  .banner-breadcrumbs {
    color: $white;
    font-size: 16px;
    font-weight: bold;
    a {
      color: $white;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
