// ============================================
// Truncate
// ============================================

@mixin truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
