// ============================================
// Header
// ============================================
.header {
  background: $white;
  .header--logo {
    margin-top: 10px;
    margin-bottom: 10px;
    width: auto;
    @media (max-width: 530px) {
      width: 100%;
    }
  }
  .header--navigation {
    margin-bottom: 0;
    display: inline-block;
    li {
      display: inline-block;
    }
    a {
      color: $color-txt;
      margin-left: 30px;
      &.current {
        color: $secondary-theme;
      }
      &:hover {
        color: $secondary-theme;
        text-decoration: none;
      }
    }
    .children {
      display: none;
      background: $white;
      width: 250px;
      left: 0;
      box-shadow: 10px 10px 25px 0 rgba(114, 102, 89, 0.2);
      border-radius: 5px;
      border: solid 1px #bfbfbf;
      padding: 5px 10px;
      z-index: 9999;
      li {
        display: block;
        text-align: left;
        &:last-child {
          a {
            margin-bottom: 0;
          }
        }
        a {
          margin-left: 0;
          margin-bottom: 7.5px;
          display: block;
        }
      }
    }
    .has-children {
      position: relative;
      &:hover {
        .children {
          display: block;
          position: absolute;
        }
      }
    }
  }
  .header--menuopen {
    font-size: 26px;
    vertical-align: top;
    color: $color-txt;
  }
  .header--customsearchform {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    @media (max-width: 992px) {
      margin-left: 0;
      margin-right: 15px;
    }
    @media (min-width: 992px) {
      margin-left: 15px;
      margin-right: 0;
    }
    input.action {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: $color-txt;
      position: absolute;
      top: 50%;
      right: 7.5px;
      transform: translateY(-50%);
    }
    input.text {
      display: inline-block;
      text-align: left;
      border: 1px solid #bfbfbf;
      border-radius: 5px;
      padding: 2.5px 30px 2.5px 7.5px;
      max-width: 200px;
      @media (max-width: 992px) {
        background: #ebebeb;
        border: 0;
        padding: 8px;
      }
      @media (max-width: 467px) {
        max-width: 140px;
      }
    }
  }
  .header--social {
    a {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .fa-facebook-f {
      color: #3b5998;
    }
    .fa-linkedin-in {
      color: #0e76a8;
    }
    .fa-instagram {
      color: #ff8c00;
      background: -webkit-linear-gradient(#800080, #ff8c00);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  background-color: $white;
  overflow-x: hidden;
  padding-top: 30px;
  padding-bottom: 130px;
  transition: 0.5s;
  .sidenav--language {
    margin-left: 32px;
    a {
      padding: 0;
      display: inline-block;
    }
  }
  .sidenav--nav-button {
    position: relative;
    display: block;
    width: 100%;
    button {
      background: $secondary-theme;
      border: $secondary-theme;
      position: absolute;
      right: 0;
      top: 0;
      &[aria-expanded='true'] {
        i::before {
          content: '\f068';
        }
      }
    }
    [id*='menu'] {
      background: #9c948b;
      padding-top: 15px;
      padding-bottom: 15px;
      a {
        color: $white;
      }
    }
  }
  .sidenav--social {
    padding-left: 32px;
    margin-top: 50px;
    left: 0;
    a {
      padding-left: 0;
      display: inline-block;
      font-size: 25px;
    }
    .fa-facebook-f {
      color: #3b5998;
    }
    .fa-linkedin-in {
      color: #0e76a8;
    }
    .fa-instagram {
      color: #ff8c00;
      background: -webkit-linear-gradient(#800080, #ff8c00);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 16px;
    color: $color-txt;
    display: block;
    transition: 0.3s;
    &:hover {
      color: $color-txt;
      text-decoration: underline;
    }
  }
  .closebtn {
    position: absolute;
    top: 21px;
    right: 10px;
    font-size: 26px;
    margin-left: 50px;
    z-index: 9999;
    &:hover {
      text-decoration: none;
    }
  }
}
.maincontent.active::before {
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(61, 170, 232);
  background: linear-gradient(0deg, rgba(61, 170, 232, 1) 13%, rgba(255, 147, 86, 1) 45%);
  opacity: 0.4;
  z-index: 99998;
}
