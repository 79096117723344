// ============================================
// Typography
// ============================================

// Large typography
// ============================================

.typography {
}

// Vertical rythm
// ============================================

h1,
h2,
h3,
h4,
h5,
h6,
.h1
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: NixieOne Regular;
  text-transform: uppercase;
  color: $secondary-theme;
}
h1,
h2,
.h1,
.h2 {
  font-size: 40px;
  &::first-letter {
    font-size: 45px;
  }
}
h3,
.h3 {
  font-size: 30px;
  &::first-letter {
    font-size: 35px;
  }
}
h4,
.h4 {
  font-size: 24px;
  &::first-letter {
    font-size: 28px;
  }
}

.typography {
  p,
  address,
  ul,
  ol,
  pre,
  blockquote {
    margin-bottom: em($space, $fs);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.typography-compact {
  p,
  address,
  ul,
  ol,
  pre,
  blockquote {
    margin-bottom: em($space-half, $fs);
  }
}

// Headings
// ============================================

%typography-heading {
  font-weight: bold;
  font-family: $font;
}

%typography-heading-lg {
  $h-fs: nth(map-get($fs-h2, ''), 1);

  @include fs($fs-h3);
  margin-bottom: em($space-half, $h-fs);

  &:not(:first-child) {
    margin-top: em($space-plusHalf, $h-fs);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

%typography-heading-sm {
  $h-fs: nth(map-get($fs-h4, ''), 1);

  @include fs($fs-h4);
  margin-bottom: em($space-half, $h-fs);

  &:not(:first-child) {
    margin-top: em($space-plusHalf, $h-fs);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.typography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend %typography-heading;
  }

  h1,
  h2 {
    @extend %typography-heading-lg;
  }

  h3,
  h4,
  h5,
  h6 {
    @extend %typography-heading-sm;
  }
}

.typography-compact {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    $h-fs: nth(map-get($fs-h4, ''), 1);

    @include fs($fs-h4);
    margin-bottom: em($space-half, $h-fs);

    &:not(:first-child) {
      margin-top: em($space, $h-fs);
    }
  }
}

// Anchors
// ============================================

.typography {
  a {
    text-decoration: underline;
    transition: opacity $motion;

    &:hover {
      opacity: 0.4;
    }
  }
}

.typography-primary {
  a {
    color: $primary;
    text-decoration: none;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}

// Blockquote
// ============================================

.typography {
  blockquote {
    border-left: 5px solid $primary;
    padding-left: calc(1.7em - 10px);
    font-style: italic;
  }
}

// Lists
// ============================================

// General
.typography {
  ul,
  ol {
    li {
      position: relative;
      margin-bottom: em(5px, $fs);
    }

    li:last-child {
      margin-bottom: 0;
    }

    ul,
    ol {
      margin-bottom: 0;
    }
  }

  li {
    ul,
    ol {
      margin-top: em(5px, $fs);
    }
  }
}

.typography-compact {
  ul,
  ol {
    li {
      margin-bottom: 0;
    }
  }

  li {
    ul,
    ol {
      margin-top: 0;
    }
  }
}

// Ordered
.typography {
  ol {
    counter-reset: ol;
  }

  ol li {
    padding-left: 1.7em;
    counter-increment: ol;

    &::before {
      @include pos(0, top left) content: counter(ol) '.';
    }
  }
}

// Unordered
.typography {
  ul li {
    padding-left: 1.4em;

    &::before {
      @include pos(0.7em 0.2em, top left);
      @include size(6px);
      background-color: currentColor;
      content: '';
      border-radius: 99px;
    }
  }
}

// Iframe
//============================================

.typography {
  iframe {
    width: 100%;
  }
}

// Images, Media & Captions
// ============================================

// Images general
.typography {
  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }
}

// Images & caption containers
.typography {
  .captionImage,
  > p img,
  > img {
    &.center,
    &.leftAlone,
    &.left,
    &.right {
      width: auto !important;
      max-width: 100%;
    }

    &.center {
      margin: 0 auto;
      display: block;
    }

    @include mq($mq-sm) {
      &.left {
        float: left;
        margin-right: 1em;
        margin-bottom: $lh * 1em;
      }

      &.right {
        float: right;
        margin-left: 1em;
        margin-bottom: $lh * 1em;
      }
    }
  }
}

// Media a.k.a. Videos
.typography {
  .embed {
    width: auto !important;

    &::before {
      padding-bottom: (9 / 16) * 100%;
    }
  }

  img.embed {
    &::before {
      display: none;
    }
  }
}

// Caption containers
.typography {
  .captionImage {
    margin-bottom: $lh * 1em;

    &.center,
    &.leftAlone,
    &.left,
    &.right {
      margin-bottom: $lh * 1em;
    }

    img {
      max-width: 100%;
    }
  }
}

// Captions
.typography {
  .caption {
    font-size: 90%;
  }

  .media + .caption,
  img + .caption {
    padding-top: $space-third;
    margin: 0;
  }
}
