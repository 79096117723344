// ============================================
// Footer
// ============================================
.footer {
  .footer--links {
    a {
      color: $color-txt;
      margin-bottom: 5px;
      display: block;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .footer--contactinformatie {
    li {
      position: relative;
      padding-left: 25px;
    }
    i {
      color: $primary-theme;
      position: absolute;
      left: 0;
      line-height: 24px;
    }
    a {
      color: $color-txt;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .footer--social {
    font-size: 25px;
    a {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .fa-facebook-f {
      color: #3b5998;
    }
    .fa-linkedin-in {
      color: #0e76a8;
    }
    .fa-instagram {
      color: #ff8c00;
      background: -webkit-linear-gradient(#800080, #ff8c00);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .footer--subfooter {
    background: $primary-theme;
    color: $white;
    p {
      margin-bottom: 0;
    }
    a {
      color: $white;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
