// ============================================
// Text
// ============================================

// Text Transform
// ============================================

.u-tt-caps,
.u-tt-uppercase {
  text-transform: uppercase;
}

// Font Weight
// ============================================

.u-fw-bold {
  font-weight: bold;
}

// Line Height
// ============================================

.u-lh-small {
  line-height: 1.2;
}

// Truncate
// ============================================

.u-truncate {
  @include truncate;
}
