// sass-lint:disable empty-line-between-blocks

// ============================================
// Spacing utility mixin
// ============================================

@mixin generate-spacing-helpers($configs...) {
  @each $config in $configs {
    $mq: map-get($config, 'mq');
    $pull: map-get($config, 'pull');
    $push: map-get($config, 'push');
    $padd: map-get($config, 'padd');
    $name: map-get($config, 'name');

    $sizes: map-get($config, 'sizes');

    @if str-length($name) != 0 {
      $name: '#{\@}#{$name}';
    }

    @if not length($sizes) {
      @warn 'No sizes defined';
    }

    @include mq($mq) {
      @each $key, $val in $sizes {
        @if str-length($key) != 0 {
          $key: '-#{$key}';
        }

        @if $pull {
          @include generate-spacing-block(
            $key,
            $name,
            $val,
            $type: 'pull',
            $multiplier: -1
          );
        }

        @if $push {
          @include generate-spacing-block($key, $name, $val, $type: 'push');
        }

        @if $padd {
          @include generate-spacing-block(
            $key,
            $name,
            $val,
            $type: 'padd',
            $prop: 'padding'
          );
        }
      }
    }
  }
}

@mixin generate-spacing-block(
  $key,
  $name,
  $val,
  $type,
  $prop: 'margin',
  $multiplier: 1
) {
  @if type-of($val) == number {
    $val: $val * $multiplier;
  }

  .u-#{$type}-horz#{$key}#{$name},
  .u-#{$type}#{$key}#{$name},
  .u-#{$type}-lft#{$key}#{$name} {
    #{$prop}-left: $val;
  }

  .u-#{$type}-horz#{$key}#{$name},
  .u-#{$type}#{$key}#{$name},
  .u-#{$type}-rgt#{$key}#{$name} {
    #{$prop}-right: $val;
  }

  .u-#{$type}-vert#{$key}#{$name},
  .u-#{$type}#{$key}#{$name},
  .u-#{$type}-top#{$key}#{$name} {
    #{$prop}-top: $val;
  }

  .u-#{$type}-vert#{$key}#{$name},
  .u-#{$type}#{$key}#{$name},
  .u-#{$type}-btm#{$key}#{$name} {
    #{$prop}-bottom: $val;
  }
}
