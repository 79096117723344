.artikel--date {
  display: inline;
}
.artikel--content {
  display: inline;
  p {
    display: inline;
  }
}
.artikel--gallerij-top {
  height: 350px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  .swiper-slide {
    background-size: cover !important;
    background-position: center;
    border-radius: 5px;
    overflow: hidden;
  }
}
.artikel--gallerij-thumbs {
  width: 100%;
  height: 110px;
  margin-top: 20px;
  .swiper-slide {
    background-size: cover !important;
    background-position: center;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    &.swiper-slide-thumb-active {
      border: solid 4px $secondary-theme;
      box-sizing: border-box;
    }
  }
}
