// ============================================
// Browser
// ============================================

// 1. Over specify to 'activate' fixed positioning
//    on browsers that support it.
// 2. Lengths are in pixels to support old browsers.

body .browserWarning {
  position: fixed; // 1.
}

.browserWarning {
  @include pos(0, left bottom);
  width: 100%;
  z-index: 1000000;
  background: $color-error;
  color: $white;
  font-size: 16px; // 2.
  padding: 20px;
}

.browserWarning_inner {
  h3 {
    @include fs($fs-h3);
    font-weight: bold;
    margin-bottom: $space-half;
  }

  a {
    text-decoration: underline;
  }
}
