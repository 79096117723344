// ============================================
// Responsive Images
// ============================================

.u-img-full img,
.u-img-full {
  width: 100%;
  height: auto;
}

.u-img-max img,
.u-img-max {
  max-width: 100%;
  width: auto;
  height: auto;
}
