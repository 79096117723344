// ============================================
// Just for screenreaders
// ============================================

.u-sr-only {
  width: 1px !important;
  height: 1px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
}
