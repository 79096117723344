// ============================================
// Icon
// ============================================

// Base
// ============================================

.i {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  color: transparent;
  speak: none;
}
