// ============================================
// Font sizes
// ============================================

// Create responsive font-sizes (& line-heigts)
// from Sass maps.

// Expects a map. Map-keys should map to breakpoint
// names or ' for no breakpoint. Values can be a number
// (font-size) or a list of numbers, fist value -> `font-size`,
// second value (line-height).

// Example
// ============================================

// Vriables `_vars.scss`
// $breakpoints: (
//   'sm': 728px,
// );
//
// $fs-p-sm: (
//   ': (12px, 1.5),
//   'sm': 14px,
// );

// Usage
// .my-class{
//   @include fs($fs-p-sm)
// }

// Output
// .my-class {
//   font-size: 12px;
//   line-height: 1.5;
// }

// @media all and (min-width: 728px) {
//   .my-class {
//     font-size: 14px;
//   }
// }

// Mixin
// ============================================

// Requires a map by the name of `$breakpoints`.
// Requires mq mixin.

$breakpoints: () !default;

@mixin font-sizes($map, $bps: $breakpoints) {
  @each $bp, $fs in $map {
    @include mq(
      if(str-length($bp) > 0, '(min-width: #{map-get($bps, $bp)})', false)
    ) {
      font-size: nth($fs, 1);

      @if (length($fs) > 1) {
        line-height: nth($fs, 2);
      }
    }
  }
}

// Alias
@mixin fs($map, $bps: $breakpoints) {
  @include font-sizes($map, $bps);
}
